import React, { useState, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import useOnClickOutside from './useOnClickOutside';

const StickyContact = (props) => {
    const ref = useRef();
    const [open, setOpen] = useState(false);

    const toggleVisibility = () => {
        if (!open) {
            window?.dataLayer?.push({
                event: 'sitecore',
                event_category: 'sticky_contact',
                event_action: 'open',
            });
        }
        setOpen(!open);
    };

    useOnClickOutside(ref, () => setOpen(false), open);

    const { text, telephone, email, contactLink } = props;

    return (
        <section ref={ref} className={`sticky-contact ${open ? 'open' : ''}`}>
            <button
                type="button"
                className="mobile-overlay"
                onClick={toggleVisibility}
                aria-label="{stickyContactOpen ? text.openLabel : text.closedLabel}"
            />
            <div className="contact-container" aria-hidden={!open}>
                <button
                    type="button"
                    className="tab"
                    onClick={toggleVisibility}
                    aria-controls="contact-information"
                    aria-expanded={open}
                >
                    {open && <span className="ikon-kryss-24" aria-hidden="true" />}
                    {open ? text.openLabel : text.closedLabel}
                </button>
                <div id="contact-information" className="contact-info text-regular-big" aria-hidden={!open}>
                    <ul>
                        {text.info && (
                            <li>
                                <p>{text.info}</p>
                            </li>
                        )}
                        {telephone && (
                            <li>
                                <span className="ikon-telefon-36" aria-hidden="true" />
                                <a href={`tel:${telephone}`}>{telephone}</a>
                            </li>
                        )}
                        {email && (
                            <li>
                                <span className="ikon-mail-36" aria-hidden="true" />
                                <a href={email.url}>{email.text}</a>
                            </li>
                        )}
                        {contactLink && (
                            <li>
                                <a
                                    className="contact-link"
                                    href={contactLink.url}
                                    target={contactLink.target}
                                    rel="noopener noreferrer"
                                >
                                    {contactLink.text}
                                    <span className="ikon-pil-hoeyre" aria-hidden="true" />
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    );
};
StickyContact.propTypes = {
    text: PropTypes.object,
    telephone: PropTypes.string,
    email: PropTypes.object,
    contactLink: PropTypes.object,
};

export default hot(StickyContact);
