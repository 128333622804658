import React from 'react';
import { AppContainer } from 'react-hot-loader';
import loadContainers from '@eika-infrastruktur/ui-engine-render';
import StickyContact from './components/sticky-contact';

const renderApp = (namespace, MyApp) => {
    loadContainers(namespace, ({ config }) => (
        <AppContainer>
            <MyApp {...config.data} />
        </AppContainer>
    ));
};
renderApp('chat.web.sticky-contact', StickyContact);
