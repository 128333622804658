import { useEffect } from 'react';

const useOnClickOutside = (ref, handler, isOpen) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!isOpen || !ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        if (isOpen) {
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
        } else {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        }

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, isOpen]);
};

export default useOnClickOutside;
